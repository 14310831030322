.section1, .section2, .section3, .section4{
  padding-top: 2rem;
  display: flex;
  gap: 10rem;
  border-bottom: 1px solid lightgrey;
}

.section2, .section4{
  flex-direction: row-reverse;
}

.heading_mobile_v{
  display: none;
  font-size: 1.5rem;
  margin: 0;
}

.txt_wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.txt_heading{
  font-size: 1.5rem;
  margin: 0;
}

.txt_para{
  margin: 0;
}

.ts_illustration{
  width: 36rem;
  height: 24rem;
  /* box-shadow: 1px 1px 1px grey; */
}

@media only screen and (max-width: 1200px) {

  .section1, .section2, .section3, .section4{
    flex-direction: column;
    gap: 3rem;
  }

  .txt_heading{
    display: none;
  }

  .heading_mobile_v{
    display: flex;
  }

  .ts_illustration{
    width: 100%;
    height: auto;
  }
}