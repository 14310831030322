*{
  box-sizing: border-box;
}

a{
  color: black;
  text-decoration: none;
}

p{
  margin: 0;
}

::-webkit-scrollbar-track{
  background: rgb(128, 128, 128,0.2);
}

::-webkit-scrollbar {
  background-color: rgb(128, 128, 128,0.7);
  border-radius: 0.75rem;
  width: 1.25rem;
  margin-left: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255,0.6);
  border-radius: 0.75rem;
  height: 3rem;
  padding: 0.25rem;
  width: 1rem;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

@media only screen and (max-width: 500px){
  html{
    font-size: 10px;
  }
}

/* @media only screen and (max-width: 1050px){
  html{
    font-size: 14px;
  }
} */

@media only screen and (min-width : 1650px){
  html{
    font-size: 18px;
  }
}