/* ContentCard */

.content_card{
  width: 55%;
  max-height: 36rem;
  background-color: rgb(255, 255, 255,0.7);
  /* position: absolute;
  top: 4rem;
  right: 4rem; */
  border-radius: 3rem;
  animation: cardanimate 1s;
}

.card_container{
  width: 100%;
  height: 100%;
  padding: 2.5rem 4rem;
}

.title{
  text-align: center;
  font-size: 1.25rem;
  font-family: "Zen Dots", sans-serif;
  margin-bottom: 1rem;
}

.content_container{
  height: 100%;
  width: 100%;
}

.content_container>p{
  margin-bottom: 0;
}

@keyframes cardanimate {
  0%{
    opacity: 0.6;
    transform: scale(0.4)
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (max-width : 1050px){
  .content_card{
    width: 100%;
    max-height: 36rem;
  }

  .card_container{
    padding: 2rem 3rem;
  }
}