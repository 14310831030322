.vision_mission_container {
  margin-top: 1rem;
  display: flex;
  gap: 4rem;
  padding-bottom: 2rem;
}

.vision_mission_container>div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.vm_heading{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.team_imgs_container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 4rem;
}

.team_member_img {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}

.member_info{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

.member_name{
  font-weight: 600;
  font-size: 1.5rem;
}

.member_designation{
 font-size: 1.1rem;
}


@media only screen and (max-width: 1200px) {
  .vision_mission_container {
    flex-direction: column;
    gap: 2rem;
  }

  .vision_mission_container>div>p{
    margin-bottom:0 ;
  }

  .team_imgs_container{
    flex-wrap: wrap;
    gap: 2rem;
  }

  .team_member_img{
    width: 10rem;
    height: 10rem;
  }
}