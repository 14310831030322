.contactus_page_container{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  text-align: center;
}

.contactus_page_container>div>p{
  margin: 0;
}

.contactus_page_container>div>b>p{
  margin: 0;
}

.icon_container{
  display: flex;
  gap: 0.5rem;
  font-size: 1.75rem;
  padding: 0.5rem 0rem;
}

.icon_wrapper{
  border: 1px solid transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .ehiive_emphasis{
  color: #9FEE26;
  font-weight: 600;
} */