.section1,
.section2,
.section3 {
  padding-top: 2rem;
  display: flex;
  gap: 10rem;
  border-bottom: 1px solid lightgrey;
}

.section2 {
  flex-direction: row-reverse;
}

.heading_mobile_v {
  display: none;
  font-size: 1.5rem;
  margin: 0;
}

.txt_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.txt_para {
  /* text-align: center; */
  margin: 0;
}

.txt_heading {
  font-size: 1.5rem;
  margin: 0;
  /* text-align: center; */
}

.st_illustration {
  width: 36rem;
  height: 24rem;
}

@media only screen and (max-width: 1200px) {

  .section1,
  .section2,
  .section3 {
    flex-direction: column;
    gap: 2rem;
  }

  .txt_heading {
    display: none;
  }

  .heading_mobile_v {
    display: flex;
  }

  .st_illustration {
    /* width: 60%;
    height: auto; */
    width: 24rem;
    height: 16rem;
  }
}