.icon_container{
  display: flex;
  gap: 0.5rem;
  font-size: 1.75rem;
  padding: 0.5rem 0rem;
}

.icon_wrapper{
  border: 1px solid transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}