.section{
    margin-top: 2rem;
    display: flex;
    gap: 8rem;
    border: 1px solid lightgrey;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 1.5rem;
    flex-direction: row-reverse;
  }
  
  
  .heading_mobile_v {
    display: none;
    font-size: 1.5rem;
    margin: 0;
  }
  
  .txt_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 2rem;
  }
  
  .txt_para {
    /* text-align: center; */
    margin: 0;
    text-align: start;
    
  }
  
  .txt_heading {
    font-size: 1.5rem;
    margin: 0;
    /* text-align: center; */
  }
  
  .st_illustration {
    width: 36rem;
    height: 24rem;
    padding: 1rem 1rem;
    border-radius: 1.5rem;
  }
  
  @media only screen and (max-width: 1200px) {
  
    .section {
      flex-direction: column;
      gap: 2rem;
      padding: 1.5rem;
    }
  
    .txt_heading {
      display: none;
    }
  
    .heading_mobile_v {
      display: flex;
    }
  
    .st_illustration {
      /* width: 60%;
      height: auto; */
      width: 24rem;
      height: 16rem;
    }
  }