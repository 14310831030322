.footer {
  background-color: rgb(90, 90, 90);
  color: white;
  /* position: relative; */
  /* position: absolute;
  bottom: 0; */
}

.footer_container {
  padding: 2rem 5rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: white;
}

.footer_container>div>a {
  color: white;
}

.logo_footer {
  width: 20rem;
  margin-left: -0.4rem;
  padding-bottom: 0.5rem;
}

.dialogue {
  display: flex;
  gap: 0.5rem;
}

.heart_icon {
  display: inline-block;
}

.india_flag_icon {
  width: 2rem;
  display: inline-block;
}

.footer_container1 {
  display: flex;
  flex-direction: column;
}

.footer_container2 {
  display: flex;
  flex-direction: column;
}

.footer_container3 {
  display: flex;
  flex-direction: column;
}

.input_container {
  position: relative;
  width: 20rem;
  height: 2rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}

.input_field {
  width: 100%;
  height: 2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  outline: none;
  font-size: 1rem;
  color: black;
  padding-left: 0.8rem;
}
.input_field::placeholder{
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.sub_btn {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: -2px;
  padding: 2px 20px;
  background-color: #9FEE26;
  color: black;
  font-weight: 600;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.footer_store_btn {
  width: 10rem;
  height: 5rem;
  padding-top: 1rem;
}

.tnc_container {
  display: flex;
  gap: 1rem;
  font-size: 0.75rem;
}

.tnc_container>a {
  color: white;
}

@media only screen and (max-width : 500px) {
  .footer_container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer_container4 {
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid black;
  }

  .icon_container {
    justify-content: center;
  }

  .footer_store_btn_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width : 850px) {
  @media only screen and (max-height : 1050px) {
    .footer_container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  @media only screen and (max-height : 1200px) {
    .footer_container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

@media only screen and (max-height : 1370px) {
  @media only screen and (max-width : 920px) {
    .footer_container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  @media only screen and (max-width : 1050px) {
    .footer_container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}