.linked_page_template{
  background-color: white;
}

.linked_page_nav{
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 4rem;
  /* border-bottom: 1px solid black; */
  /* background-color: #EAF3DC; */
}

.menu_btn{
  width: 3rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.open_modal_dots{
  width: 3rem;
}

.ehiive_logo{
  width: 18rem;
  /* position: absolute;
  top: 2rem;
  right: 3rem; */
}

.banner_img{
  width: 100%;
}

.page_container{
  padding: 2rem 6rem;
}

.linked_template_heading{
  text-align: center;
  font-size: 1.5rem;
  font-family: "Zen Dots", sans-serif;
}

.content_wrapper{
  padding: 2rem 2rem;
  font-size: 1.25rem;
}

@media only screen and (max-width: 1200px) {
  .page_container{
    padding:2rem 1rem;
  }

  .ehiive_logo{
    width: 12rem;
  }
}