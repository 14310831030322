.read_more_container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
}

.readmore {
  color: #151514;
  background: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  text-transform: uppercase;
  font-family: "Zen Dots", sans-serif;
  box-shadow: 2px 1px 0px #000;
}