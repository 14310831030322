.faq_card {
  height: 26rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 1rem;
}


.faq_card::-webkit-scrollbar {
  background-color: rgb(128, 128, 128, 0.5);
  border-radius: 0.75rem;
  width: 1.25rem;
  margin-left: 0.5rem;
}

.faq_card::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 0.6);
  border-radius: 0.75rem;
  height: 2rem;
  padding: 0.25rem;
  width: 1rem;
}

.accordion {
  box-shadow: none;
  border-radius: 1rem;
  border-top: none;
}

@media only screen and (max-width: 500px) {
  .faq_card {
    height: 24rem;
  }
}