.bg {
  width: 100%;
  height: 100%;
  /* position: relative; */
  margin-bottom: -10px;
}

.bg_mobile {
  display: none;
}

.menu_btn {
  width: 3rem;
  position: fixed;
  top: 3rem;
  left: 3rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 3;
}

.open_modal_dots {
  width: 3rem;
}


.logo_white {
  width: 15rem;
  position: fixed;
  top: 3rem;
  right: 3rem;
  z-index: 5;
}

/* .welcome_txt_container {
  position: absolute;
  top: calc(50% - 6rem);
  left: calc(50% - 6rem);
  color: white;
} */

.sroll_to_roll {
  width: 10rem;
  position: absolute;
  top: 40%;
  left: calc(50% - 5rem);

}

.welcome_txt {
  font-size: 4rem;
  font-family: "Zen Dots", sans-serif;
}

.scooter {
  width: 8rem;
  position: fixed;
  top: 4rem;
  left: calc(24% - 4rem);
}

.scooter_insection{
  width: 8rem;
  position: fixed;
  top: 4rem;
  left: calc(24% - 9.5rem);
  /* transition-delay: 0.05s ; */
  transition-duration: 0.05s;
}

/* .orange_car[data-in-section="true"] {
  transform: rotate(-35deg);
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  left: calc(24% - 3rem);
} */

.scooter_insection_docking{
  position: absolute;
  width: 8rem;
  top: 61rem;
  left: 26rem;
}

.scooter_docked{
  position: absolute;
  width: 8rem;
  top: 67rem;
  left: 26rem;
}

.section1 {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 40rem;
  top: 59rem;
}

.section1_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.charging_dock1 {
  width: 18.5rem;
  position: absolute;
  top: calc(50% - 5.5rem);
  left: calc(36% - 9.5rem);
  z-index: 3;
}

.orange_car {
  width: 8rem;
  position: absolute;
  top: 77rem;
  left: 26rem;
}

.orange_car2{
  width: 8rem;
  position: fixed;
  top: 3rem;
  left: 26rem;
}

.orange_car3{
  width: 8rem;
  position: absolute;
  top: 110rem;
  left: 24rem;
  z-index: 3;
}

.orange_car4{
  width: 8rem;
  position: fixed;
  top: 10rem;
  left: 24.5rem;
  z-index: 3;
}

.orange_car5{
  width: 8rem;
  position: absolute;
  top: 200rem;
  left: 24rem;
  z-index: 3;
}


.section2 {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 40rem;
  top: 112.5rem;
}

.section2_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.charging_dock2 {
  position: absolute;
  width: 18.5rem;
  top: calc(50% - 5.75rem);
  left: calc(36% - 9.5rem);
  z-index: 3;
}

.section3 {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 40rem;
  top: 166rem;
}

.section3_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.troubleshooters {
  position: absolute;
  width: 15rem;
  top: calc(50% - 6.2rem);
  left: calc(36% - 7.5rem);
}

.section4 {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 40rem;
  top: 219.5rem;
}

.section4_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.charging_dock3 {
  position: absolute;
  width: 18.5rem;
  top: calc(50% - 6rem);
  left: calc(36% - 9.5rem);
  z-index: 3;
}

.section5 {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 40rem;
  top: 273rem;
}

.section5_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.charging_dock4 {
  position: absolute;
  width: 18.5rem;
  top: calc(50% - 6.25rem);
  left: calc(36% - 9.5rem);
  z-index: 3;
}

.blue_car {
  width: 7rem;
  position: absolute;
  top: 237rem;
  left: 27rem;
}

/* Media queries */
@media only screen and (max-width: 450px) {
  @media only screen and (max-height: 950px) {
    .bg {
      display: none;
    }

    .bg_mobile {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
    }

    .orange_car {
      width: 6.5rem;
      left: calc(43% - 3.25rem);
      top: 4rem;
    }

    .section1 {
      top: 26rem;
      height: 60rem;
    }

    .scooter {
      top: 39rem;
      left: 20.5rem;
    }

    .section1_container,
    .section2_container,
    .section3_container,
    .section4_container,
    .section5_container {
      align-items: end;
    }

    .section2 {
      top: 92rem;
      height: 56rem;
    }

    .section3 {
      top: 147rem;
      height: 58rem;
    }

    .section4 {
      top: 206rem;
      height: 62rem;
    }

    .section5 {
      top: 268rem;
      height: 63rem;
    }

    .blue_car {
      width: 6rem;
      top: 280rem;
      left: 22rem;
    }

    .charging_dock1,
    .charging_dock2,
    .charging_dock3,
    .charging_dock4 {
      left: calc(55% - 3rem);
      width: 13rem;
    }

    .charging_dock1 {
      top: 12.5rem;
    }

    .charging_dock2 {
      top: 12.5rem;
    }

    .charging_dock3 {
      top: 12.5rem;
    }

    .charging_dock4 {
      top: 11rem;
    }

    .troubleshooters {
      top: 10rem;
      left: calc(65% - 6rem);
      width: 12rem;
    }

    .open_modal_btn {
      position: fixed;
      top: 3rem;
      left: 3rem;
      z-index: 3;
    }
  }
}

@media only screen and (max-width: 420px) {

  @media only screen and (max-height: 916px) {
    .section1 {
      top: 24rem;
    }

    .scooter {
      top: 38rem;
      left: 19.5rem;
    }

    .section2 {
      top: 87.5rem;
      height: 52rem;
    }

    .section3 {
      top: 140rem;
      height: 57rem;
    }

    .troubleshooters {
      width: 11.5rem;
    }

    .section4 {
      top: 196.5rem;
      height: 58rem;
    }

    .section5 {
      top: 255rem;
      height: 63rem;
    }

    .blue_car {
      width: 6rem;
      top: 267rem;
      left: 21rem;
    }
  }

  @media only screen and (max-height: 900px) {
    .section1 {
      top: 24rem;
    }

    .scooter {
      top: 38rem;
      left: 19.5rem;
    }

    .section2 {
      top: 88rem;
      height: 52rem;
    }

    .section3 {
      top: 140rem;
      height: 57rem;
    }

    .troubleshooters {
      width: 11.5rem;
    }

    .section4 {
      top: 197.5rem;
      height: 60rem;
    }

    .section5 {
      top: 257rem;
      height: 63rem;
    }

    .blue_car {
      width: 6rem;
      top: 269rem;
      left: 21rem;
    }

  }


  @media only screen and (max-height: 850px) {
    .section1 {
      top: 24rem;
      height: 58rem;
    }

    .charging_dock1 {
      top: 10rem;
    }

    .scooter {
      top: 35rem;
      left: 18.5rem;
    }


    .section2 {
      top: 82rem;
      height: 52rem;
    }

    .section3 {
      top: 134rem;
      height: 53rem;
    }

    .section4 {
      top: 187rem;
      height: 56rem;
    }

    .troubleshooters {
      top: 8rem;
      width: 11rem;
    }

    .charging_dock3 {
      top: 10.5rem;
    }

    .section5 {
      top: 243rem;
      height: 60rem;
    }

    .charging_dock4 {
      top: 9rem;
    }

    .blue_car {
      width: 6rem;
      top: 253rem;
      left: 20rem;
    }
  }

  @media only screen and (max-height:750px) {
    .section1 {
      top: 21rem;
      height: 57rem;
    }

    .scooter {
      top: 32rem;
      left: 16.5rem;
    }

    .section2 {
      top: 74rem;
    }

    .section3 {
      top: 123rem;
      height: 52rem;
    }

    .section4 {
      top: 171.5rem;
      height: 53rem;
    }

    .section5 {
      top: 223rem;
      height: 56rem;
    }

    .blue_car {
      width: 5.5rem;
      top: 233rem;
      left: 18rem;
    }
  }

  @media only screen and (max-height:710px) {
    .section1 {
      top: 23rem;
    }

    .scooter {
      top: 33rem;
      left: 17.5rem;
    }

    .section2 {
      top: 78rem;
    }

    .section3 {
      top: 128rem;
    }

    .section4 {
      top: 179rem;
      height: 55rem;
    }

    .section5 {
      top: 233rem;
      height: 58rem;
    }

    .blue_car {
      width: 5.5rem;
      top: 243rem;
      left: 19rem;
    }
  }

}

@media only screen and (min-width : 1650px) {
  .section1 {
    top: 66rem;
    height: 42rem;
  }

  .scooter {
    width: 10rem;
    top: 85rem;
    left: 28.5rem;
  }

  .charging_dock1,
  .charging_dock2,
  .charging_dock3,
  .charging_dock4 {
    width: 20rem;
    left: calc(36% - 10.5rem);
  }

  .section2 {
    top: 125rem;
    height: 42rem;
  }

  .section3 {
    top: 186rem;
    height: 42rem;
  }

  .troubleshooters {
    width: 16rem;
    left: calc(36% - 8rem);
  }

  .section4 {
    top: 244rem;
    height: 42rem;
  }

  .section5 {
    top: 304rem;
    height: 42rem;
  }

  .blue_car {
    width: 7rem;
    position: absolute;
    top: 322rem;
    left: 30.5rem;
  }

}

@media only screen and (max-width : 850px) {

  .bg {
    display: none;
  }

  .bg_mobile {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
  }

  .orange_car {
    left: calc(43% - 4rem);
  }

  .section1_container,
  .section2_container,
  .section3_container,
  .section4_container,
  .section5_container {
    align-items: end;
  }

  @media only screen and (max-height : 1050px) {

    .section1 {
      top: 28.5rem;
      height: 68rem;
    }

    .charging_dock1,
    .charging_dock2,
    .charging_dock3,
    .charging_dock4 {
      width: 16rem;
      left: calc(64% - 8rem);
    }

    .charging_dock1 {
      top: 14rem;
    }

    .section2 {
      top: 102rem;
      height: 58rem;
    }

    .charging_dock2 {
      top: 14rem;
    }

    .section3 {
      top: 162rem;
      height: 65rem;
    }

    .troubleshooters {
      width: 13.5rem;
      top: 12.5rem;
      left: calc(65% - 7rem);
    }

    .section4 {
      top: 229rem;
      height: 66rem;
    }

    .charging_dock3 {
      top: 14rem;
    }

    .section5 {
      top: 296rem;
      height: 70rem;
    }

    .charging_dock4 {
      top: 14rem;
    }
  }

  @media only screen and (max-height : 1200px) {}
}

@media only screen and (max-height : 1370px) {
  @media only screen and (max-width : 920px) {}

  @media only screen and (max-width : 1050px) {}
}