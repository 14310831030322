.homecard_txt{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.home_btn_container{
  display: flex;
  gap: 4rem;
  width: 100%;
  justify-content: center;
  padding-top: 2rem;
}

.home_store_btn{
  width: 12rem;
}

@media only screen and (max-width: 500px) {
  /* .home_store_btn{
    width: ;
  } */
}