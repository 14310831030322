.contact_txt {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ehiive_emphasis {
  color: #9FEE26;
  padding-left: 0.25rem;
  font-weight: 600;
}

.contact_left>div{
  margin-bottom: 1rem;
}

.contact_left>div>b>p{
  margin-bottom: 0.25rem;
}

/* .contact_left>div>p{
  margin-top: 0.5rem;
} */

.contact_container {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 10rem;
}

.contact_form>div{
  margin-bottom: 0.5rem;
}

.contact_form>div>input {
  padding-left: 1rem;
  border: none;
  font-size: 1.25rem;
  padding-bottom: 0.1rem;
}

.contact_form>div>input:focus {
  outline: none;
}

.contact_form>div {
  border-bottom: 1px solid lightgrey;
  margin-top: 1rem;
}

.submit_btn_container{
  display: flex;
  justify-content: center;
  border: none;
}

.submit_btn {
  background-color: greenyellow;
  border-radius: 1.5rem;
  border: none;
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.icon_container {
  display: flex;
  gap: 0.5rem;
  font-size: 1.75rem;
  padding: 0.5rem 0rem;
}

.icon_wrapper {
  border: 1px solid transparent;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: black;
  color: white;
}

@media only screen and (max-width:1200px) {
  .contact_container {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .contact_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}