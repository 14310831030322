.nav_modal{
  position: fixed;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 3rem;
  background-color: rgb(255, 255, 255,0.9);
  border-radius: 2rem;
  z-index: 5;
  height: 90%;
  padding: 2rem;
  font-family: "Zen Dots", sans-serif;
}

.nav_modal_container{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 2rem;
  justify-content:center ;
  align-items: center;
  height: 100%;
  margin-top: -1rem;
}

.nav_modal_container>a{
  border-bottom: 4px solid lightgrey;
}

.nav_close_btn{
  font-size: 2rem;
  border: none; 
  background-color: transparent;
  cursor: pointer;
}
